import request from '@/utils/request'

// 租赁绑定 /app-api/eye/lease-order/sn-bind
export function rentBind(data) {
  return request({
    url: '/app-api/eye/lease-order/sn-bind',
    method: 'post',
    data
  })
}

// 退租订单提交 /app-api/eye/lease-order/return
export function orderReturn(data) {
  return request({
    url: '/app-api/eye/lease-order/return',
    method: 'post',
    data
  })
}

// 续租订单提交 /app-api/eye/lease-order/renew
export function orderRenew(data) {
  return request({
    url: '/app-api/eye/lease-order/renew',
    method: 'post',
    data
  })
}

// 新租订单提交 /app-api/eye/lease-order/new
export function orderNew(data) {
  return request({
    url: '/app-api/eye/lease-order/new',
    method: 'post',
    data
  })
}

// 换租订单提交 /app-api/eye/lease-order/change
export function orderChange(data) {
  return request({
    url: '/app-api/eye/lease-order/change',
    method: 'post',
    data
  })
}

// 租赁订单分页 /app-api/eye/lease-order/page
export function orderList(params) {
  return request({
    url: '/app-api/eye/lease-order/page',
    method: 'get',
    params
  })
}

// 租赁订单详情 /app-api/eye/lease-order/get
export function orderGet(params) {
  return request({
    url: '/app-api/eye/lease-order/get',
    method: 'get',
    params
  })
}

// 租赁配置 /app-api/vision/lease-prod/get
export function rentConfig(params) {
  return request({
    url: '/app-api/vision/lease-prod/get',
    method: 'get',
    params
  })
}

// 文件上传 /app-api/infra/file/upload
export function uploadFile(data) {
  return request({
    url: '/app-api/infra/file/upload',
    method: 'post',
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    data,
  })
}

// 用户最近一次收货地址 /app-api/eye/lease-order/lastAddr
export function lastAddr(params) {
  return request({
    url: '/app-api/eye/lease-order/lastAddr',
    method: 'get',
    params
  })
}

// 根据设备SN查询可绑定订单-分页 /app-api/eye/lease-order/pageBySn
export function pageBySn(params) {
  return request({
    url: '/app-api/eye/lease-order/pageBySn',
    method: 'get',
    params
  })
}
