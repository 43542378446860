<template>
  <div class="container">
    <div class="header"></div>
    <!-- <div class="h20"></div> -->
    <div class="new-list">
      <div class="cell">
        <div class="cell-title">SN码</div>
        <div class="cell-info">
          <van-field class="cell-l" maxlength="100" v-model="form.deviceSn" :border="false" label-width="0"
            placeholder="请输入或扫一扫" />
          <img id="scanBtn" style="zoom:1.8" class="cell-r" src="@/accesst/device/scan.png" alt="" srcset="">
        </div>
      </div>
      <div class="cell">
        <div class="cell-title">姓名</div>
        <div class="cell-info">
          <van-field class="cell-l" maxlength="20" v-model="form.nickname" :border="false" label-width="0"
            placeholder="请输入姓名" />
          <div class="cell-r">&nbsp;</div>
        </div>
      </div>
      <div class="cell">
        <div class="cell-title">手机号</div>
        <div class="cell-info">
          <van-field class="cell-l" v-model="form.mobile" type="tel" label-width="0" label="" placeholder="请输入手机号" />
          <div class="cell-r">&nbsp;</div>
        </div>
      </div>
    </div>
    <!-- 租赁订单列表 -->
    <div v-if="type == 1" class="rent-list">
      <div class="rent-list-title">租赁订单</div>
      <div v-for="item in orderList" :key="item.id" @click="checked = item.id" class="rent-list-item">
        <div class="h29"></div>
        <div class="rent-list-item-l">
          <span>租赁时长：</span>{{ item.leaseStart | dateFormat}} - {{ item.leaseEnd | dateFormat}}（{{item.leaseMonths}}个月）
        </div>
        <div class="h19"></div>
        <div class="rent-list-item-l">
          <span>设备租金：</span>¥{{ item.monthlyRent * item.leaseMonths }}
        </div>
        <div class="h19"></div>
        <div class="rent-list-item-l">
          <span>设备押金：</span>¥{{ item.deivceDeposit }}
        </div>
        <img v-if="checked === item.id" class="icon-checked" src="@/accesst/device/radio-c-icon.png" alt="" srcset="" />
        <img v-else class="icon-checked" src="@/accesst/device/radio-icon.png" alt="" srcset="" />
      </div>
      <!-- <div class="rent-list-item">
        <div class="h29"></div>
        <div class="rent-list-item-l">
          <span>租赁时长：</span>2023.12.01 - 2024.03.31（3个月）
        </div>
        <div class="h19"></div>
        <div class="rent-list-item-l">
          <span>设备租金：</span>¥1500
        </div>
        <div class="h19"></div>
        <div class="rent-list-item-l">
          <span>设备押金：</span>¥1500
        </div>
        <img class="icon-checked" src="@/accesst/device/radio-c-icon.png" alt="" srcset="" />
      </div> -->
    </div>
    <van-button @click="bind()" class="bind-btn" type="info" :disabled="disabled" :loading="disabled" block>绑定</van-button>
    <div @click="$router.push({ path: '/' })" class="back-btn">返回首页</div>
    <p>&nbsp;</p>    
    <van-number-keyboard v-model="form.mobile" :show="show" :maxlength="11" @blur="show = false" />
  </div>
</template>

<script>
import { Field, NumberKeyboard, Button } from 'vant';
// import { QrcodeStream } from 'vue-qrcode-reader'
import { bindDevice, wxJsApi } from '@/api/h5'
import { pageBySn, rentBind } from '../../api/rent';
import moment from 'moment'

// let URL = "http://www.baidu.com?name=Jack&age=25&sex=men&wife=Lucy"
function getUrlParams2(url) {
  let urlStr = url.split('?')[1]
  const urlSearchParams = new URLSearchParams(urlStr)
  const result = Object.fromEntries(urlSearchParams.entries())
  return result
}

export default {
  components: { [Field.name]: Field, [NumberKeyboard.name]: NumberKeyboard, [Button.name]: Button },
  data() {
    return {
      disabled: false,
      show: false,
      form: {
        deviceSn: this.$route.query.sn || '',
        status: 0,
        nickname: '',
        mobile: ''
      },
      orderList: [],
      type: '',
      checked: null
    }
  },
  filters: {
    dateFormat(v) {
      if(v) {
        return moment(v).format('yyyy-MM-DD')        
      } 
      return ''
    }
  },
  watch: {
    'form.deviceSn': {
      handler: function (deviceSn) {
        if(deviceSn) {
          pageBySn({deviceSn}).then(res=> {
            console.log('pageBySn ----->',  res)
            this.type = res.deviceSnType;
            this.orderList = res.list;
            this.initData(res.deviceSnType, res.list)
          }).catch(() => {
            this.initData('', [])            
          })
        } else {
          this.initData('', [])          
        }
      },
      immediate: true,  
    }
  },
  mounted() {
    // ToDo  Api res 微信jsAPI签名
    wxJsApi({ url: window.location.href }).then(res => {
      this.$scanQRCode(res, {}, (deviceSn) => {
        console.log(deviceSn, 'cb back url || deviceSn');
        let params = getUrlParams2(deviceSn)
        this.form.deviceSn = params && params.sn ? params.sn : deviceSn;
      })
    })
  },
  methods: {
    initData(type, orderList) {
      this.type = type;
      this.orderList = orderList;
      this.checked = null;
      // this.form.nickname = '';
      // this.form.mobile = '';
    },
    bind() {
      this.disabled = true;
      const form = this.form
      if(this.type == 2) { // 售卖机绑定
        if (!form.deviceSn) {
          this.$toast('请输入SN码');
          this.disabled = false;
          return false;
        }

        if (!form.nickname) {
          this.$toast('请输入姓名');
          this.disabled = false;
          return false;
        }
        var regExp = /^1[3456789]\d{9}$/;
        if (!regExp.test(form.mobile)) {
          this.$toast('请输入正确的手机号');
          this.disabled = false;
          return false
        }
        bindDevice({ ...form }).then(res => {
          console.log(res, 'bind res');
          this.$toast('绑定成功!');
          // this.$router.push({ path: '/' });
          if(res > 1) {
            this.$router.push({ path: '/' });
          } else {
            this.$store.state.showStep = true
            this.$router.push({ path: '/user/add'});
          }
        }).catch(e => {
          console.log(e, 'bind error');
          this.disabled = false;
          this.$toast(e.msg || '绑定失败');
        })
      } else if (this.type == 1) { // 租赁机绑定
        if (!form.deviceSn) {
          this.$toast('请输入SN码');
          this.disabled = false;
          return false;
        }
        if (!form.nickname) {
          this.$toast('请输入姓名');
          this.disabled = false;
          return false;
        }
        var regExp = /^1[3456789]\d{9}$/;
        if (!regExp.test(form.mobile)) {
          this.$toast('请输入正确的手机号');
          this.disabled = false;
          return false
        }
        if (!this.checked) {
          this.$toast('请选择租赁订单');
          this.disabled = false;
          return false;
        }
        rentBind({deviceSn: form.deviceSn, id: this.checked, ...form }).then(res => {
          console.log(res, 'bind 租赁 res');
          this.$toast('绑定成功!');
          if(res > 1) {
            this.$router.push({ path: '/' });
          } else {
            this.$store.state.showStep = true
            this.$router.push({ path: '/user/add'});
          }
        }).catch(e => {
          console.log(e, 'bind error');
          this.disabled = false;
          this.$toast(e.msg || '绑定失败');
        })
      } else { // 请输入正确的设备SN
        this.$toast('请输入正确的设备SN');
        this.disabled = false;
      }
    }
  }
}
</script>

<style lang="less" scoped>
.container {
  width: 100vw;
  min-height: 100vh;
  background: #f7f7f7;
  .rent-list {
    background: #f7f7f7;
    .rent-list-title {
      padding: .23rem 0 0 .38rem;
      font-weight: 500;
      font-size: .31rem;
      color: #000000;      
    }
    .rent-list-item {
      position: relative;
      margin: .19rem auto 0;
      width: 7.12rem;
      height: 2.12rem;
      background: #FFF;
      border-radius: .08rem;
      .icon-checked {
        position: absolute;
        bottom: .35rem;
        right: .3rem;
        width: .2rem;
        height: .2rem;
        z-index: 2;
        zoom: 1.2;
      }
      .h19 {
        height: .19rem;
      }
      .h29 {
        height: .29rem;
      }
      .rent-list-item-l {
        padding-left: .19rem;
        font-weight: 400;
        font-size: .27rem;
        color: #010101;
        span{
          color: #666666;
        }        
      }
    }
  }
  .new-list {
    background: #fff;
    padding: .23rem 0;
  }

  .qr-c {
    width: .4rem;
    height: .4rem;
  }

  .back-btn {
    width: 100vw;
    height: 1rem;
    line-height: 1rem;
    font-size: .32rem;
    color: #666;
    text-align: center;
    margin-top: .5rem;
  }

  .bind-btn {
    margin: 1.4rem auto 0;
    width: 4.17rem;
    height: .77rem;
    background: #0074FE;
    border-radius: .12rem;
    line-height: .77rem;
    text-align: center;
    font-size: .27rem;
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    color: #FFFFFF;
  }

  .header {
    width: 7.50rem;
    height: 6.07rem;
    background: url('../../accesst/device/band-bg.png') no-repeat;
    background-size: 7.5rem 6.07rem;
  }

  .h20 {
    height: .2rem;
  }

  .cell {
    margin: .23rem .57rem 0;
    border-bottom: .02rem solid #EEEEEE;

    /deep/ .van-cell {
      padding: .16rem 0;
    }

    /deep/ .van-field__control {
      color: #2B67D9
    }

    .cell-l {
      width: 6rem;
    }

    .cell-r {
      width: .4rem;
      // img {
      //   width: .31rem;
      //   height: .31rem;
      // }
    }

    .cell-title {
      height: .38rem;
      font-size: .27rem;
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      color: #000000;
      line-height: .38rem;
    }

    .cell-info {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
}
</style>